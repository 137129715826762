/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"

interface PurpleBulletListProps {
  children: React.ReactNode | React.ReactNode[]
  className?: string
}
const PurpleBulletList = ({ children, className }: PurpleBulletListProps) => {
  return (
    <ul
      className={className}
      sx={{
        p: 0,
        pl: "20px",
        listStyle: `url('/images/purple-bullet.svg')`,
        li: {
          mt: 2
        }
      }}
    >
      {children}
    </ul>
  )
}

export default PurpleBulletList
