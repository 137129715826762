/** @jsx jsx */
import { jsx } from "theme-ui"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "components/Common/Layout"
import PurpleHero from "components/PurpleHero"
import ServicesInfoSection from "components/services/ServicesInfoSection"
import MoveYourBrandCard from "components/Footer/MoveYourBrandCard"
import { graphql } from "gatsby"

const ServicesPage = ({
  data: {
    strapiServicesPage: { SEO }
  }
}) => {
  return (
    <Layout
      seo={{
        title: SEO.meta_title,
        description: SEO.meta_description
      }}
    >
      <PurpleHero heading="Get the tools to Win." />

      <main
        sx={{
          pb: 6
        }}
      >
        <ServicesInfoSection
          heading="Market Research"
          subHeading="Know your market and your competitors."
          listPoints={[
            "We understand targeting YOUR audience is a battle. Get the winning solution you need to connect with your customers  by understanding sales trends, projections, competitor prices, and purchasing demographics.",
            "Be the best at meeting your customer's needs."
          ]}
          imagePosition="RIGHT"
          id="market-research"
        >
          <StaticImage
            src="../../images/services-page/section-1.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="Branding"
          subHeading="Be memorable!"
          listPoints={[
            "Branding is more than a logo. its who your company is! We listen to you and define your brand to make a lasting positive first impression.",
            "So you can stand out and crush the market! "
          ]}
          imagePosition="LEFT"
          id="branding"
        >
          <StaticImage
            src="../../images/services-page/section-2.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="SEO"
          subHeading="Leverage technology to drive website traffic and sales."
          listPoints={[
            "Your future customers are scanning the horizon online - we make sure their eyes fall on you. SEO combines the best practices to make your brand internet powerhouse."
          ]}
          imagePosition="RIGHT"
          id="seo"
        >
          <StaticImage
            src="../../images/services-page/section-3.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="Social Media"
          subHeading="Grow brand awareness, advertise your product."
          listPoints={[
            "Connect with and build your audience everyday. Social media is one of the most powerful tools to produce revenue and share your brand's story with millions of people!"
          ]}
          imagePosition="LEFT"
          id="social-media"
        >
          <StaticImage
            src="../../images/services-page/section-4.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="Pay Per Click Campaigns"
          subHeading="Get Premiere placing on google and other platforms."
          listPoints={[
            "Campaigns are customizable and results-driven.",
            "Create laser-targeted processes that put your product in front of the right people in the right place at the right time."
          ]}
          imagePosition="RIGHT"
          id="pay-per-click-campaigns"
        >
          <StaticImage
            src="../../images/services-page/section-5.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="Branded Content Development"
          subHeading="Experience your Brand!"
          listPoints={[
            "Compose unforgettable content that lives, breathes, and connects with customers through branded photography, video, and written material."
          ]}
          imagePosition="LEFT"
          id="branded-content-development"
        >
          <StaticImage
            src="../../images/services-page/section-6.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="Advertisement Strategy Development"
          subHeading="Walk the path to success."
          listPoints={[
            "You have a lot happening. Let us maximize your investment through proven strategies to turbo-charge your conversion rate.",
            "From brand release, to new product release strategy, we'll create the road map for you."
          ]}
          imagePosition="RIGHT"
          id="advertisement-strategy-development"
        >
          <StaticImage
            src="../../images/services-page/section-7.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>

        <ServicesInfoSection
          heading="Entertainment Services"
          subHeading="Pre through post production needs answered here."
          listPoints={[
            "Have a project? Need a hand? Our professionals can facilitate any aspect of storytelling and every production need under one roof.",
            "Whether your needs are for still or motion imagery, Mōtus specializes in  color timing, editing, audio mix down, animation, photography - and more!"
          ]}
          imagePosition="LEFT"
          id="entertainment-services"
        >
          <StaticImage
            src="../../images/services-page/section-8.png"
            alt="Design image"
            width={590}
            height={513}
          />
        </ServicesInfoSection>
      </main>

      <MoveYourBrandCard
        sx={{
          m: "0 auto",
          position: "relative",
          bottom: [-6, -5],
          zIndex: 2
        }}
      />
    </Layout>
  )
}

export default ServicesPage

export const servicesPageQuery = graphql`
  query ServicesPage {
    strapiServicesPage {
      SEO {
        meta_title
        meta_description
        share_image {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
