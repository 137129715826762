/** @jsx jsx */
import { jsx, Container, Flex, Box, Heading, Paragraph } from "theme-ui"
import React from "react"

import PurpleBulletList from "../PurpleBulletList"

interface ServicesInfoSectionProps {
  children: React.ReactNode
  heading: string
  subHeading: string
  listPoints: string[]
  imagePosition: "LEFT" | "RIGHT"
  id: string
}
const ServicesInfoSection = ({
  children,
  heading,
  subHeading,
  listPoints,
  imagePosition,
  id
}: ServicesInfoSectionProps) => {
  const columnDirection =
    imagePosition === "RIGHT" ? "column" : "column-reverse"

  return (
    <section
      id={id}
      sx={{
        pt: [4, 6],
        color: "themeBlack"
      }}
    >
      <Container variant="container1200">
        <Flex
          sx={{
            flexDirection: [columnDirection, "row"],
            justifyContent: "space-between",
            alignItems: "center"
          }}
        >
          {imagePosition === "LEFT" && (
            <Box
              sx={{
                mt: [5, 0]
              }}
            >
              {children}
            </Box>
          )}
          <Box
            sx={{
              maxWidth: "470px",
              alignSelf: ["flex-start", "center"]
            }}
          >
            <Heading as="h3" variant="styles.h3">
              {heading}
            </Heading>
            <Heading
              as="h4"
              variant="styles.h4"
              sx={{
                mt: 2
              }}
            >
              {subHeading}
            </Heading>
            <PurpleBulletList
              sx={{
                mt: 2
              }}
            >
              {listPoints.map((text) => (
                <li key={text}>
                  <Paragraph>{text}</Paragraph>
                </li>
              ))}
            </PurpleBulletList>
          </Box>
          {imagePosition === "RIGHT" && (
            <Box
              sx={{
                mt: [5, 0]
              }}
            >
              {children}
            </Box>
          )}
        </Flex>
      </Container>
    </section>
  )
}

export default ServicesInfoSection
