/** @jsx jsx */
import { jsx, Container, Flex, Heading, Box } from 'theme-ui';
import { StaticImage } from 'gatsby-plugin-image';

import Header from 'components/Header/Header';

interface PurpleHeroProps {
  heading: string;
}
const PurpleHero = ({ heading }: PurpleHeroProps) => {
  return (
    <section
      sx={{
        height: ['574px', '700px'],
        position: 'relative',
        color: 'white',
      }}
    >
      {/* Hero image */}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 'back',
        }}
      >
        <StaticImage
          src="../images/purple-hero.svg"
          alt="Services Page Hero"
          placeholder="blurred"
          loading="eager"
          objectFit="cover"
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      {/* Text */}
      <Container variant="container1200" sx={{ pt: '215px' }}>
        <Flex
          sx={{
            height: '100%',
            justifyContent: ['center', 'flex-start'],
            alignItems: ['flex-start', 'center'],
          }}
        >
          <Heading
            as="h1"
            variant="styles.h1"
            sx={{
              maxWidth: '589px',
            }}
          >
            {heading}
          </Heading>
        </Flex>
      </Container>
    </section>
  );
};

export default PurpleHero;
